import React, { useEffect } from "react";
import "./HardwareTest.scss";
import Button from "../../../components/Button/Button";
import { paths } from "../../../services/routes/appRoutes";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
import QrReaderTest from "./components/QrReaderTest/QrReaderTest";
import TabsContainer from "../../../components/TabsContainer/TabsContainer";
import LedsTest from "./components/LedsTest/LedsTest";
import TicketPrinterTest from "./components/TicketPrinterTest/TicketPrinterTest";
import { btmLog } from "../../../services/localBackendService";
import { logSessionEvent } from "../../../services/localSessionService";
import { sessionEvents } from "../../../utils/sessionUtils";
import { useSelector } from "react-redux";

const HardwareTest = () => {
  const { sessionId } = useSelector((state) => state.session);

  useEffect(() => {
    btmLog("HardwareTest screen");
    logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, {
      screen: "HardwareTest",
    });
  }, [sessionId]);

  const navigate = useNavigate();

  return (
    <Layout className={"HardwareTest"} showFooter={false}>
      <TabsContainer
        tabs={[
          {
            title: "Lector QR",
            content: <QrReaderTest />,
          },
          {
            title: "Leds",
            content: <LedsTest />,
          },
          {
            title: "Impresora",
            content: <TicketPrinterTest />,
          },
        ]}
      />

      <Button
        type={Button.type.secondary}
        className="return-button"
        onClick={() => navigate(paths.adminMenu)}
      >
        Regresar
      </Button>
    </Layout>
  );
};

export default HardwareTest;
