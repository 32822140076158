import React, {useCallback, useContext, useEffect, useState, useRef} from 'react'
import {useNavigate} from 'react-router-dom';
import NumericPad from '../../components/NumericPad/NumericPad'
import {paths} from '../../services/routes/appRoutes';
import "./VerificationCode.scss"
import Button from '../../components/Button/Button';
import CircleTimer from '../../components/CircleTimer/CircleTimer';
import _ from "lodash";
import Layout from '../../components/Layout/Layout';
import {secondsToTimer} from '../../services/constants';
import {clearSession, setKycData} from "../../utils/redux/sessionSlice";
import {useDispatch, useSelector} from "react-redux";
import {AlertContext} from "../../components/Alerts/AlertContext";
import {btmLog} from "../../services/localBackendService";
import {useTranslation} from 'react-i18next';
import {getKycData} from "../../services/rayyoClient";
import Alert from "../Alert/Alert";
import errorAnimatedIcon from "../../assets/images/info-red-animated.gif"
import {logSessionEvent} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";
import Modal from '../../components/Modal/Modal';

export default function VerificationCode() {

  useEffect(() => {
    btmLog('VerificationCode screen').catch(console.error);
  }, []);

  const navigate= useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [alertInfo, setAlertInfo] = useState(null);
  const [suspendedUser, setSuspendedUser] = useState(false);
  const timeoutRef = useRef(null);
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const {sessionId, phoneInfo} = useSelector(state => state.session);
  const {addAlert} = useContext(AlertContext);

  const onAcceptSuspended = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    navigate(paths.home)
  }

  const handleIsBack = useCallback(() => {
    let isEmpty = true;
    code.forEach(element => {
      if (element !== '') {
        isEmpty = false;
      }
    });
    if (!isEmpty) {
      let newArray = [...code];
      let findInForeach = false;
      newArray.forEach((element, index) => {
        if (element === '') {
          newArray[index-1] = '';
          findInForeach = true;
        }
      });
      if (!findInForeach) {
        newArray[newArray.length - 1] = '';
      }
      setCode(newArray);
    }
    setButtonEnabled(false);
  }, [code]);

  const onPress = useCallback((value) => {
    if (value === 'Delete') {
      setCode(['', '', '', '', '', '']);
      setButtonEnabled(false);
      return;
    }
    if (value === 'Back') {
      handleIsBack();
      return;
    }
    let isFull = true;
    code.forEach(element => {
      if (element === '') {
        isFull = false;
      }
    });

    if (!isFull) {
      const emptyIndex = code.indexOf('');
      const newArray = [...code];
      newArray[emptyIndex] = value;
      setCode(newArray);
      setButtonEnabled(false);
      if (newArray.indexOf('') === -1) {
        setButtonEnabled(true);
      }
    }
  }, [code, handleIsBack]);

  const onBack =( reason ) => {
    logSessionEvent(sessionId, sessionEvents.BACK, {from: "VerificationCode", to: "Home", reason });
    dispatch(clearSession());
    navigate(paths.home);
    
  };

  const handleContinue = useCallback(async () => {

    const codeStr = code.join('');
    if( phoneInfo?.code !== codeStr ) {
      addAlert({message: t('Verification code not valid'), type: 'error'});
      logSessionEvent(sessionId, sessionEvents.OTP_TYPED, {code: codeStr, valid: false });
      return;
    }

    let kycData;
    try {
      kycData = await getKycData({phone: phoneInfo.phone})
      dispatch(setKycData(kycData.data));
    } catch (err) {
      addAlert({message: t('Error getting kyc data'), type: 'error'});
      logSessionEvent(sessionId, sessionEvents.ERROR, {step: "EnterPhoneNumber", critical: true, error: err });
      navigate(paths.home);
    }

    logSessionEvent(sessionId, sessionEvents.OTP_TYPED, {code: codeStr, valid: true, kyc: kycData.data });

    if(kycData.data.isBlockedUser){      
      setSuspendedUser(true)
      timeoutRef.current = setTimeout(()=>{
        navigate(paths.home)
      } ,60000)
      return;
    }

    if (!kycData.data.blackListed) {
      navigate(paths.transactionVerification);
    }

    if ((kycData.data.blackListed || kycData.data.forceKyc) && kycData.data.kycStatus !== 'verified') {
      navigate(paths.verifyIdentity);
    }

    if (kycData.data.blackListed && kycData.data.kycStatus === 'verified') {
      setAlertInfo(
        {
          title: t("Verified identity"),
          message: t("Invalid phone number to make a transaction"),
          icon: errorAnimatedIcon
        }
      );
    }

  }, [phoneInfo?.phone, phoneInfo?.code, code, navigate, addAlert, t, dispatch, sessionId]);

  useEffect(() => {
    if (code.indexOf('') === -1) {
      handleContinue().catch(console.error);
    }
  }, [code, handleContinue]);

  const onCloseAlert = () => {
    setAlertInfo(null);
    navigate(paths.home);
  };

  /**
   * Masks a phone number by replacing all but the last 4 digits with asterisks.
   *
   * @param {string} phoneNumber - The phone number to be masked
   * @returns {string} The masked phone number
   */
  const maskPhoneNumber = (phoneNumber) => "** **** "+phoneNumber.slice(-4);

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      backButtonCallback={()=>onBack("Back button pressed")}
      hideButtons={false}
    >
      <div className='VerificationCode'>
        <div className='d-flex justify-content-end'>
          <CircleTimer
            seconds={secondsToTimer}
            callback={()=>onBack("Timer finished")}
          />
        </div>
        <div className='d-flex justify-content-between mt-5'>
          <div className='instructions mt-2'>
            <div className='instructions-title'>
              <span>
                {t('Verification code') + maskPhoneNumber(phoneInfo?.phone)}
              </span>
            </div>
            <div className='instructions-description mt-2'>
              <span>
                {t('We send a verification code')}
              </span>
            </div>
          </div>
          <div className='right-side mt-2'>
            <div className='gradient-border-mask'>
              <div className='box-code'>
                {
                  _.map(code, (element, index) => (
                    <div
                      key={index}
                      className='d-flex'
                    >
                      <div
                        className='number'
                      >
                        {element}
                      </div>
                      {
                        index !== 5 &&
                        <div className='number line-separator'>
                          -
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            </div>

            <div className='mt-2'>
              <NumericPad
                onPress={onPress}
              ></NumericPad>
            </div>
            <div className='mt-3'>
              <div>
                <Button
                  onClick={handleContinue}
                  disabled={!buttonEnabled}
                >
                  {t('Verify')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        !!alertInfo &&
        <Alert
          onClose = {onCloseAlert}
          title = {alertInfo.title || t('Invalid code')}
          message = {alertInfo.message || t('Check the scanned QR. If the problem persists, contact support.')}
          icon = {alertInfo.icon || errorAnimatedIcon}
        ></Alert>
      }

      {
        suspendedUser && 
        <Modal >
          <div className="suspended-user-modal">
            <div className="suspended-user-modal-content">
              <div className='suspended-modal-logo'>
                <img src={errorAnimatedIcon} alt=""/>
              </div>

              <div className='suspended-modal-title'><span>{t('Attention!')}</span></div>
              <div className='suspended-modal-text'><span>{t('It seems there is an issue with your account. We recommend contacting support to resolve it.')}</span></div>
              <div className='suspended-modal-button'>
                <Button onClick={onAcceptSuspended}>{t('Understood')}</Button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </Layout>

  )
}
