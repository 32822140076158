import React, {useContext, useEffect, useState} from 'react'
import { v4 as uuidv4 } from 'uuid';
import "./Home.scss"
import buyBitcoin from "../../assets/images/buy_bitcoin.svg"
import sellBitcoin from "../../assets/images/sell_bitcoin.svg"
import redeemTicket from "../../assets/images/redeem_ticket.svg"
import {useNavigate} from 'react-router-dom'
import {paths} from "../../services/routes/appRoutes";
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import {clearSession, startSession} from "../../utils/redux/sessionSlice";
import {useDispatch} from "react-redux";
import Rate from "../../components/Rate/Rate";
import ExchangeRateClient from "../../services/ExchangeRateClient";
import {getSetting, settingKey} from "../../services/appSettings";
import {sessionTypes} from "../../utils/sessionUtils";
import {btmLog} from "../../services/localBackendService";
import { useTranslation } from 'react-i18next';
import OutOfService from '../OutOfService/OutOfService';
import {AlertContext} from '../../components/Alerts/AlertContext';
import Alerts from '../../components/Alerts/Alerts';
import {getLeds, turnOffLed} from "../../services/hardware/ledsService";
import {startRecording, stopRecording} from "../../services/localSecurityCameraService";
import CheckBtmStatusService from '../../services/CheckBtmStatusService';
import LocalWsApi from "../../services/LocalWsApi";

const checkBtmStatusService = new CheckBtmStatusService();
const wsApi = LocalWsApi.getInstance();

export default function Home() {

    useEffect(() => {
        btmLog('Home screen');
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {addAlert} = useContext(AlertContext);

    const [btmStatus, setBtmStatus] = useState(checkBtmStatusService.getStatus());
    const [buyPrice, setBuyPrice] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [rate, setRate] = useState(0);
    // Used to force a reload of the exchange rate when the config changes
    const [configVersion, setConfigVersion] = useState(0);

    useEffect(() => {
        dispatch(clearSession());

        (async () => {
            const ledId = await getLeds()
            for( let oneLed in ledId ){
                await turnOffLed( ledId[oneLed] ).catch(console.error);
                await new Promise(resolve => setTimeout(resolve, 100));
            }
        })();

        stopRecording({}).catch(console.error);
    },[dispatch]);

    // handle status changes
    useEffect(() => {
        checkBtmStatusService.onStatusChange(setBtmStatus);
        return () => checkBtmStatusService.removeListener(setBtmStatus);
    }, []);

    // on config changes, refresh the exchange rate
    useEffect(() => {
        const listener = () =>setConfigVersion(v => v + 1);
        wsApi.subscribe('checkStatus.receivedConfig', listener);
        return () => wsApi.unsubscribe('checkStatus.receivedConfig', listener);
    }, []);

    // Exchange rate listener
    useEffect(() => {

        if( btmStatus.localServerOffline ){
            console.error('Local server offline. Cannot listen to exchange rate changes');
            return;
        }
        console.log('Starting exchange rate listener');
        const rateClient = new ExchangeRateClient();
        (async () => {

            const buyMultiplier = await getSetting(settingKey.BUY_MULTIPLIER);
            const withdrawMultiplier = await getSetting(settingKey.WITHDRAW_MULTIPLIER);

            const {rate} = await rateClient.getMxnRate();

            setBuyPrice(rate * buyMultiplier);
            setSellPrice(rate * withdrawMultiplier);
            setRate(rate);

            rateClient.onMxnRateChange(({rate}) => {
                console.log('Rate changed:', rate);
                setRate(rate);
                setBuyPrice(rate * buyMultiplier);
                setSellPrice(rate * withdrawMultiplier);
            });
        })().catch(e=>console.error("Failed to initialize exchange rate client", e));

        return () => {
            rateClient.removeAllListeners();
        }
    }, [ btmStatus.localServerOffline, configVersion ]);


    const goToSession = (path, sessionType) => {

        if ( !buyPrice || !sellPrice || !rate ) {
            addAlert({message: t('Loading exchange rates, wait a moment'), type: Alerts.alertType.warning});

            //Reload after the user saw the message to clean any error with the missing rates.
            //This should not happen, but it assures we do not go into a session without a exchange rate.
            setTimeout(()=>{
                document.location.href = paths.home;
            }, 3000);

        }
        const sessionId = uuidv4();
        const session = {
            sessionId: sessionId,
            sessionType,
            marketPrice: rate,
            buyPrice,
            sellPrice
        };

        dispatch(startSession(session));

        startRecording({sessionId}).catch(console.error);
        btmLog(`Starting session: ${JSON.stringify(session)}`);
        navigate(path);

    };

    const disableBuyBtn = btmStatus.disableBuy || btmStatus.mainWalletOutOfBtc;

    return (
        <Layout showHeader showFooter allowAdminScreen>
            <OutOfService />
            <div className='Home'>
                <div className='d-flex flex-column align-items-center justify-content-center content'>

                    <div className='exchange-block'>
                        <Rate
                            text={t('Buy')}
                            value={buyPrice}
                        />
                        <Rate
                            text={t('Sell')}
                            value={sellPrice}
                        />
                    </div>

                    <div>
                        <Title
                            text={t('Choose your operation')}
                        ></Title>
                    </div>
                    <div className="grid grid-cols-3 mt-5">

                        <button
                            className={"box"}
                            onClick={() => goToSession(paths.enterPhoneNumber, sessionTypes.BUY)}
                            disabled = {disableBuyBtn}
                        >
                            <div className="box-content">
                                <div className="box-image">
                                    <img src={buyBitcoin} alt="sell-bitcoin"/>
                                </div>
                                <div className="box-text">
                                    <span>
                                        {t('Buy Bitcoin')}
                                    </span>
                                </div>
                            </div>
                        </button>

                        <button
                            className="box"
                            disabled={!!btmStatus.disableSell}
                            onClick={() => goToSession(paths.enterPhoneNumber, sessionTypes.SELL)}
                        >
                            <div className="box-content">
                                <div className="box-image">
                                    <img src={sellBitcoin} alt="sell-bitcoin"/>
                                </div>
                                <div className="box-text">
                                    <span>
                                        {t('Sell Bitcoin')}
                                    </span>
                                </div>
                            </div>
                        </button>

                        <button
                            className="box"
                            disabled={!!btmStatus.disableRedeem}
                            onClick={() => goToSession(paths.scanTicket, sessionTypes.REDEEM)}
                        >
                            <div className="box-content">
                                <div className="box-image">
                                    <img src={redeemTicket} alt="sell-bitcoin"/>
                                </div>
                                <div className="box-text">
                                    <span>
                                        {t('Redeem Ticket')}
                                    </span>
                                </div>
                            </div>
                        </button>

                    </div>
                </div>
            </div>
        </Layout>)
}
