import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../../services/routes/appRoutes";
import "./ScanTicket.scss";
import scanWallet from "../../assets/images/scan_wallet.gif";
import scanArrow from "../../assets/images/scan_arrow.gif";
import CircleTimer from "../../components/CircleTimer/CircleTimer";
import InstructionsBox from "../../components/InstructionsBox/InstructionsBox";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { secondsToTimer } from "../../services/constants";
import { useDispatch, useSelector } from "react-redux";
import QrReaderClient from "../../services/hardware/QrReaderClient";
import {
  confirmWithdrawal,
  validateReception,
  getUserMxnDisposalData,
  confirmUserMxnDisposal
} from "../../services/rayyoClient";
import { AlertContext } from "../../components/Alerts/AlertContext";
import Alerts from "../../components/Alerts/Alerts";
import { cleanQrCodeAddress, validateAddress } from "../../utils/btcUtils";
import BanknoteRecyclerClient from "../../services/hardware/BanknoteRecylcerClient";
import { btmLog } from "../../services/localBackendService";
import { useTranslation } from "react-i18next";
import { canDispenseAmount } from "../../services/frontBanknoteService";
import FullScreenAlert from "../../components/FullScreenAlert/FullScreenAlert";
import {
  blinkLed,
  getLeds,
  turnOffLed,
} from "../../services/hardware/ledsService";
import { createTransaction } from "../../services/localTransactionService";
import { transactionTypes } from "../../utils/transactionUtils";
import pendingTransactionIcon from "../../assets/images/pending_transaction.svg";
import incompletePaymentIcon from "../../assets/images/incomplete_payment.svg";
import errorAnimatedIcon from "../../assets/images/info-red-animated.gif";
import { stopRecording } from "../../services/localSecurityCameraService";
import Alert from "../Alert/Alert";
import { setTxId } from "../../utils/redux/sessionSlice";
import {logSessionEvent, setSessionBtmTxId} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";

export default function ScanTicket() {
  useEffect(() => {
    btmLog("ScanTicket screen");
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [notEnoughFunds, setNotEnoughFunds] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);
  const { sessionId } = useSelector((state) => state.session);

  const { addAlert } = useContext(AlertContext);

  useEffect(() => {
    getLeds().then(ledId => blinkLed(ledId.QR_READER)).catch(console.error);
    return () => {
      getLeds().then(ledId => turnOffLed(ledId.QR_READER)).catch(console.error);
    };
  }, []);

  const buildAlertMessage = useCallback((errorCode) => {
      let textSize = '';
      let title = t("Invalid QR code");
      let message = t(
        "Check the scanned QR. If the problem persists, contact support."
      );
      let icon = errorAnimatedIcon;

      if (errorCode === "NOT_PAYED") {
        title = t("Pending transaction");
        message = t(
          "Try scanning the ticket later. If the problem persists, contact support."
        );
        textSize = 'xl';
        icon = pendingTransactionIcon;
      }

      if (errorCode === "REDEEMED") {
        title = t("Redeemed ticket");
        message = t(
          "Please verify the operation and contact support if there is an error."
        );
      }

      if (errorCode === "TIMEOUT") {
        title = t("Invalid QR code");
        message = t(
          "The registration QR code is invalid or has expired. Verify the QR or generate a new one."
        );
      }

      if (errorCode === "WRONG_AMOUNT_UP") {
        textSize = 'xs';
        title = t("Wrong amount");
        message = t(
          "We have detected a larger deposit related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount."
        );
        icon = incompletePaymentIcon;
      }

      if (errorCode === "WRONG_AMOUNT_DOWN") {
        textSize = 'xs';
        title = t("Wrong amount");
        message = t("The QR code has not been completely payed. Contact support");
        icon = incompletePaymentIcon;
      }

      if (errorCode === "MULTIPLE_TX") {
        textSize = 'xs';
        title = t("Multiple deposits");
        message = t(
          "We have detected multiple deposits related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount."
        );
        icon = incompletePaymentIcon;
      }

      setAlertInfo({
        title: title,
        message: message,
        icon: icon,
        duration: 8000,
        textSize,
      });
    },
    [t]
  );

  const validateTransaction = async (address, rayyoDisposal) => {
    const parsedResponse = {};
    if (rayyoDisposal) {
      const { data } = await getUserMxnDisposalData({ address });
      parsedResponse['valid'] = !!data.txId;
      parsedResponse['txId'] = data.txId;
      parsedResponse['amount'] = data.amount;
      parsedResponse['error'] = null;
    } else {
      const { data } = await validateReception({ address });
      parsedResponse['valid'] = data.valid;
      parsedResponse['txId'] = data.btmTxId;
      parsedResponse['amount'] = data.mxnAmount / 100;
      parsedResponse['error'] = data.error;
    }
    return parsedResponse;
  };

  const confirmTransaction = useCallback(async (validationResult, rayyoDisposal) => {

    const requestData = rayyoDisposal
      ? { txId: validationResult.txId }
      : { btmTxId: validationResult.txId, sessionId: sessionId };
  
    const { data } = rayyoDisposal
      ? await confirmUserMxnDisposal(requestData)
      : await confirmWithdrawal(requestData);
  
    const confirmed = rayyoDisposal
      ? !!data.finalizedAt
      : data.success
  
    return confirmed;
  }, [sessionId]);

  useEffect(() => {

    // Flag to avoid processing multiple QR codes at the same time
    let processingRead  = false;

    // Set up the QR reader event listener
    const handleQrRead = async (data) => {

      if(processingRead){
        btmLog("[ScanTicket] Ignoring QR code read, already processing one.");
        return;
      }
      processingRead = true;
    
      try{
        const rayyoDisposal = data.includes('rayyo_disposal:')
        const address = cleanQrCodeAddress(data);
        
        if (!rayyoDisposal && !validateAddress(address)) {
          setAlertInfo({
            title: t("Invalid QR code"),
            message: t(
              "Check the scanned QR. If the problem persists, contact support."
            ),
            icon: errorAnimatedIcon,
          });
    
          logSessionEvent(sessionId, sessionEvents.QR_READ, { value: address, valid: false });
          // Start processing again after the alert is closed.
          setTimeout(() => (processingRead=false), 5000);
          return;
        }
             
        logSessionEvent(sessionId, sessionEvents.QR_READ, { value: address, valid: true });
    
        const validationResult = await validateTransaction(address, rayyoDisposal)
        if( validationResult.txId ){
          await setSessionBtmTxId(sessionId, validationResult.txId);
        } 
        logSessionEvent(sessionId, sessionEvents.REDEEM_VALIDATION, { ...validationResult });
        dispatch(setTxId({ txId: validationResult.txId }));
        
        if (!validationResult.valid) {
          buildAlertMessage(validationResult.error);
          // Start processing again after the alert is closed.
          setTimeout(() => (processingRead=false), 8000);
          return;
        }
    
        const hasEnoughFunds = await canDispenseAmount({ amount: validationResult.amount });
        if (!hasEnoughFunds) {
          setNotEnoughFunds(true);
          setTimeout(() => {
            navigate(paths.home);
          }, 5000);
    
          logSessionEvent(sessionId, sessionEvents.ERROR, {step: "ScanTicket", critical: true, error: "Not enough funds" });
    
          return;
        }
    
        try {
          await stopRecording({ fireAndForget: false });
        } catch (e) {
          btmLog(`[ScanTicket] Error on stop video: ` + e);
        }
    
        let confirmed
        try{
          confirmed = await confirmTransaction(validationResult, rayyoDisposal)
        }catch(e){
          const errStr = '[ScanTicket] Error confirming transaction: ' + e?.message || e;
          btmLog(errStr);
          logSessionEvent(sessionId, sessionEvents.ERROR, {step: "ScanTicket", critical: true, error: errStr});
          addAlert({
            message: t("Unknown server error"),
            type: Alerts.alertType.error,
          });
          navigate(paths.home);
          return;
        }
    
        if (!confirmed) {
          addAlert({
            message: t("Unknown server error"),
            type: Alerts.alertType.error,
          });
          logSessionEvent(sessionId, sessionEvents.ERROR, {step: "ScanTicket", critical: true, error: "Unknown server error. " + JSON.stringify(confirmed)});
          navigate(paths.home);
          return;
        }
    
        //Dispense the amount
        const banknoteClient = new BanknoteRecyclerClient();
        await banknoteClient.dispense(validationResult.amount);
    
        await createTransaction({
          amount: validationResult.amount,
          type: transactionTypes.BUY,
          username: "n/a",
          notes: 0,
          txId: validationResult.txId,
        });
    
        logSessionEvent(sessionId, sessionEvents.REDEEM_SUCCESS, { amount: validationResult.amount, txId: validationResult.txId });
        navigate(paths.successRedemption);
        
      }catch(e){
        const errStr = '[ScanTicket] Error handling QR read: ' + e?.message || e;
        btmLog(errStr);
        logSessionEvent(sessionId, sessionEvents.ERROR, {step: "ScanTicket", critical: true, error: errStr});
        addAlert({
          message: t("Unknown error"),
          type: Alerts.alertType.error,
        });
        navigate(paths.home);
      }
    }

    const qrReaderClient = new QrReaderClient();
    qrReaderClient.onRead(handleQrRead);

    return () => {
      qrReaderClient.offRead(handleQrRead);
    };
  }, [dispatch, navigate, addAlert, t, sessionId, buildAlertMessage, confirmTransaction, ]);

  const onBack = (reason) => {
    btmLog("Finishing session with reason: " + reason);
    navigate(paths.home);
  };

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      backButtonCallback={() => {
        onBack("Scan wallet screen back button.");
      }}
      hideButtons={false}
      className="ScanTicket"
    >
      <div className="time-container">
        <CircleTimer
          seconds={secondsToTimer}
          callback={() => {
            onBack("Scan ticket screen timer.");
          }}
        />
      </div>
      <div className="title text-center mt-5">
        <Title text={t("Scan your ticket")}></Title>
      </div>
      <div className="d-flex justify-content-between content">
        <div>
          <InstructionsBox
            title={t("Instructions")}
            list={[
              t("Open your wallet and select the option to send Bitcoin"),
              t("Wait for the transaction to be confirmed"),
              t("Scan the QR code of your ticket"),
              t("You will receive your money in cash"),
            ]}
            boxClass="box-instructions"
            titleClass="box-instructions-title"
          ></InstructionsBox>
        </div>
        <div className="scan-image">
          <img src={scanWallet} alt="scan" className="scan-image-animated"/>
          <img src={scanArrow} alt="Arrow" className="arrow-image-animated"/>
        </div>
      </div>

      {notEnoughFunds && (
        <FullScreenAlert className={"ScanTicket-alert"}>
          <p className="message">
            {t(
              "We are sorry, we do not have enough funds to dispense the transaction. Please try again later."
            )}
          </p>
        </FullScreenAlert>
      )}

      {!!alertInfo && (
        <Alert
          onClose={() => setAlertInfo(null)}
          title={alertInfo.title || t("Invalid code")}
          message={
            alertInfo.message ||
            t("Check the scanned QR. If the problem persists, contact support.")
          }
          icon={alertInfo.icon || errorAnimatedIcon}
          textSize={alertInfo.textSize || ''}
          duration={alertInfo.duration || 5000}
        ></Alert>
      )}
    </Layout>
  );
}
