import React, { useEffect, useContext } from 'react';
import { AlertContext } from '../../../../../components/Alerts/AlertContext';
import TextInput from '../../../../../components/TextInput/TextInput';
import useFormState from '../../../../../hooks/useFormState';
import Button from '../../../../../components/Button/Button';
import { blinkLed, getLeds, turnOffLed, cleanLedCache } from '../../../../../services/hardware/ledsService';
import { saveSetting } from '../../../../../services/appSettings';
import './LedConfig.scss';

const LedConfig = () => {
    const { form, setForm, handleSimpleChange } = useFormState({});
    const { addAlert } = useContext(AlertContext);

    useEffect(() => {
        const fetchLeds = async () => {
            const ledConfig =await getLeds();
            setForm(ledConfig);
        };
        fetchLeds();
    }, [setForm]);

    const blinkTest = (id) => {
        blinkLed(id);
        setTimeout(() => {
            turnOffLed(id);
        }, 3000);
    };

    const saveLedconfig = async () => {
        try {
            await saveSetting("LED_CONFIG", JSON.stringify(form));
            cleanLedCache()
            addAlert({
                type: "success",
                message: "Configuración guardada",
            });
        } catch (e) {
            addAlert({
                type: "error",
                message: `Error al guardar configuración: ${e.message}`,
            });
        }
    };

    return (
        <>
            <div className='leds-grid'>
            {Object.keys(form).map(key => (
                <div className='led-item' key={key}>
                    <h3 className='led-name'>{key}</h3>
                    <TextInput
                        type="number"
                        id={key}
                        value={form[key]}
                        onChange={handleSimpleChange(key)}
                        className={'led-input'}
                    />
                    <Button className={'led-button'} type={Button.type.secondary} onClick={() => blinkTest(form[key])}>Probar</Button>
                </div>
            ))}
            </div>

            <div className="d-flex align-items-center action-buttons led-button-container">
                <Button className={'save-button'} onClick={saveLedconfig}>Guardar</Button>
            </div>
        </>
        
    );
}

export default LedConfig;
