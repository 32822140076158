import React, {useContext, useEffect, useRef, useState} from 'react';
import './RegisterBtm.scss';
import TextInput from "../../components/TextInput/TextInput";
import {urlLayout} from "../../utils/keyboard";
import {getSetting, settingKey, saveSetting} from "../../services/appSettings";
import QrReaderClient from "../../services/hardware/QrReaderClient";
import {registerBTM} from "../../services/rayyoClient";
import {useNavigate} from "react-router-dom";
import {AlertContext} from "../../components/Alerts/AlertContext";
import Layout from "../../components/Layout/Layout";
import {paths} from "../../services/routes/appRoutes";
import Button from "../../components/Button/Button";
import {btmLog} from "../../services/localBackendService";
import {logSessionEvent} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";
import {useSelector} from "react-redux";
import Alert from "../Alert/Alert";
import errorAnimatedIcon from "../../assets/images/info-red-animated.gif";
import successCheckAnimated from "../../assets/images/success-check-animated.gif";
import {useTranslation} from "react-i18next";
import {reboot} from "../../services/frontCommandSerivce";


const RegisterBtm = () => {

    const navigate = useNavigate();
    const [savedServer, setSavedServer] = useState('');
    const [server, setServer] = useState('');
    const {addAlert} = useContext(AlertContext);
    const {sessionId} = useSelector((state) => state.session);
    const { t } = useTranslation();
    const [alertInfo, setAlertInfo] = useState(null);

    useEffect(() => {
        btmLog('RegisterBtm screen').catch(console.error);
        logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, { screen: "ManageCashbox" });
    }, [sessionId]);

    useEffect(() => {
        (async () => {
            getSetting(settingKey.RAYYO_SERVER_URL)
                .then( (url) => {
                    setServer(url||'');
                    setSavedServer(url||'');
                })
                .catch( (e) => {
                    addAlert({type:'error', message: 'Error al cargar la url del servidor. '+e?.message});
                });
        })();
    }, [addAlert]);

    const saveServer = () => {
        if(!server)
            return addAlert({type:'error', message: 'La url del servidor no puede estar vacía'});
        if(server === savedServer)
            return;

        saveSetting(settingKey.RAYYO_SERVER_URL, server)
            .then( () => {
                setSavedServer(server)
                addAlert({type:'success', message: 'Url del servidor guardada correctamente'});
            })
            .catch( (e) => {
                addAlert({type:'error', message: 'Error al guardar la url del servidor. '+e?.message});
            });
    }

    const qrReader = useRef( new QrReaderClient() );
    useEffect(()=>()=>qrReader.current.removeAllListeners(),[]);
    const [scanning, setScanning] = useState(false);

    const scanKey = () => {
        setScanning(true);
        qrReader.current.onRead((key) => {
            setScanning(false);
            qrReader.current.removeAllListeners();
            qrReader.current.stopRead();
            registerBTM(key)
              .then(() => {
                  setAlertInfo({
                      title: t('BTM linked'),
                      message: t('The BTM has been successfully linked. Rebooting in 5 seconds...'),
                      icon: successCheckAnimated,
                      duration: 10000,
                      onClose: () => {
                          setAlertInfo(null);
                          reboot({operatorName: 'registerBtm'})
                              .catch(console.error);
                      }
                  });
              })
              .catch((err) => {
                  console.log(err);
                  setAlertInfo({
                      title: t('Error linking BTM'),
                      message: t('The registration QR code is invalid or has expired. Verify the QR or generate a new one.'),
                      icon: errorAnimatedIcon,
                  });
              })
        });
    }



    return (
        <Layout className={"RegisterBtm"} showFooter={false}>

            <h1 className='title'>Registro de cajero</h1>
            <div className='content'>
                <div className='server-block'>
                    <label className='server-label' htmlFor='server'>Servidor</label><br/>
                    <TextInput id='server' placeholder='Servidor' className='server-input' value={server} onChange={setServer} layout={urlLayout}/>
                </div>
                <button className='btn btn-save' onClick={saveServer} disabled={savedServer === server}>Guardar</button>

                <hr/>

                {!!savedServer && !scanning &&
                    <button className='btn' onClick={scanKey}>
                        Escanear llave de registro
                    </button>}

                { !!scanning &&
                    <p>Leyendo clave de registro...</p>}

                <hr/>
                <Button type={Button.type.secondary} onClick={()=>{qrReader.current.stopRead();navigate(paths.adminMenu)}}>
                    Regresar
                </Button>

            </div>

            {!!alertInfo && (
              <Alert
                onClose={ alertInfo.onClose || (() => setAlertInfo(null)) }
                title={ alertInfo.title }
                message={ alertInfo.message }
                textSize={ 'md' }
                icon={ alertInfo.icon || errorAnimatedIcon}
                duration={ alertInfo.duration || 5000 }
              ></Alert>
            )}

        </Layout>
    );
};

export default RegisterBtm;
