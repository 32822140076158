import React, {useCallback, useContext, useEffect, useState} from 'react'
import "./Login.scss"
import Layout from '../../../components/Layout/Layout'
import useFormState from '../../../hooks/useFormState';
import {AlertContext} from '../../../components/Alerts/AlertContext';
import Alerts from '../../../components/Alerts/Alerts';
import {login} from '../../../utils/redux/authSlice';
import {useNavigate} from 'react-router-dom';
import Button from '../../../components/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {operatorLogin} from "../../../services/frontOperatorService";
import {btmLog} from "../../../services/localBackendService";
import {secondsToTimer} from "../../../services/constants";
import CircleTimer from "../../../components/CircleTimer/CircleTimer";
import {paths} from "../../../services/routes/appRoutes";
import TextInput from "../../../components/TextInput/TextInput";
import {getSetting, settingKey} from "../../../services/appSettings";
import {v4 as uuidv4} from "uuid";
import {startSession, setOperator} from "../../../utils/redux/sessionSlice";
import {startRecording} from "../../../services/localSecurityCameraService";
import {sessionEvents, sessionTypes} from "../../../utils/sessionUtils";
import {logSessionEvent} from "../../../services/localSessionService";
import Alert from "../../Alert/Alert";
import errorAnimatedIcon from "../../../assets/images/info-red-animated.gif";
import {useTranslation} from "react-i18next";

export default function Login() {

    const {sessionId} = useSelector((state) => state.session);
    const {form, handleSimpleChange} = useFormState({});
    const {addAlert} = useContext(AlertContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [version, setVersion] = useState('0.0.0');
    const [btmId, setBtmId] = useState('0');
    const [showAlertInfo, setShowAlertInfo] = useState(false);
    const { t } = useTranslation();


    useEffect(() => {
        btmLog('Login screen');

        const newSessionId = uuidv4();
        const session = {
            sessionId: newSessionId,
            sessionType: sessionTypes.ADMIN,
        };

        dispatch(startSession(session));

        startRecording({sessionId: newSessionId}).catch((err)=>{
            console.error(err);
            btmLog(`Error on starting recording. msg: ${err?.message || 'Unknown error.'}`);
        });

    }, [dispatch]);


    useEffect(() => {
        (async ()=> {
            try {
                const version = await getSetting( settingKey.APP_VERSION );
                setVersion(version);
            } catch (error) {
                btmLog(`Error on getting app version. msg: ${error?.message || 'Unknown error.'}`);
                console.error(error);
            }
            try {
                const btmId = await getSetting( settingKey.BTM_ID );
                setBtmId(btmId);
            } catch (error) {
                btmLog(`Error on getting btm ID. msg: ${error?.message || 'Unknown error.'}`);
                console.error(error);
            }
        })();
    },[]);

    const submitted = useCallback(async (e) => {
        e.preventDefault();
        try {
            if (!form.username || !form.password) {
                setShowAlertInfo(true);
                logSessionEvent(sessionId, sessionEvents.ADMIN_LOGIN, {success: false, error: 'Usuario y contraseña son requeridos.', user: form.username});
                return;
            }
            const {data: response} = await operatorLogin(form);
            if (!response || !response.valid) {
                setShowAlertInfo(true);
                logSessionEvent(sessionId, sessionEvents.ADMIN_LOGIN, {success: false, error: response?.error || 'Invalid login', user: form.username});
                return;
            }

            logSessionEvent(sessionId, sessionEvents.ADMIN_LOGIN, {success: true, user: form.username});
            dispatch(login(response.operator));
            dispatch(setOperator(response.operator.username));
            navigate(paths.adminMenu);
        } catch (error) {
            addAlert({message: error?.message || 'Unknown error.', type: Alerts.alertType.error});
            await btmLog(`Error on operator login. msg: ${error?.message || 'Unknown error.'}`);
        }
    }, [addAlert, form, dispatch, navigate, sessionId]);

    return (
        <Layout
            showHeader={true}
            showFooter={false}
            className={'Login'}
        >

            <div className={'timer-container'}>
                <CircleTimer seconds={secondsToTimer} callback={() => navigate(paths.home)}/>
            </div>

            <div className='content'>

                <form className='form' onSubmit={submitted}>
                    <div className="form-inner">
                        <div className='field'>
                            <label htmlFor="username">
                                Usuario
                            </label>
                            <TextInput
                                type="text"
                                id='username'
                                value={form.username || ''}
                                onChange={handleSimpleChange('username')}
                            />
                        </div>
                        <div className='field'>
                            <label htmlFor="username">
                                Contraseña
                            </label>
                            <TextInput
                                type="password"
                                id='password'
                                value={form.password || ''}
                                onChange={handleSimpleChange('password')}
                            />
                        </div>
                        <div className='field'>
                            <Button className='login-btn'>
                                Ingresar
                            </Button>
                        </div>
                    </div>
                </form>

            </div>

            <div className='app-data'>
                <p className='data-item'>BTM: {btmId}</p>
                <p className='data-item'>V: {version}</p>
            </div>

            <Button type={Button.type.secondary} className='exit-btn' onClick={()=>navigate(paths.boot)}>
                Salir
            </Button>

            {!!showAlertInfo && (
              <Alert
                onClose={() => setShowAlertInfo(false)}
                title={t('Incorrect credentials')}
                textSize={'md'}
                message={t('The combination of username and password is not valid. Please verify your information and try again.')}
                icon={errorAnimatedIcon}
              ></Alert>
            )}
        </Layout>
    )
}
