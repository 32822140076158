import React, { useEffect, useState } from "react";
import "./QrReaderTest.scss";
import scanWallet from "../../../../../assets/images/scan_wallet.gif";
import {
  blinkLed,
  getLeds,
  turnOffLed,
} from "../../../../../services/hardware/ledsService";
import QrReaderClient from "../../../../../services/hardware/QrReaderClient";
import { getQrReaderConfig } from "../../../../../services/hardware/hardwareConfig";
import { btmLog } from "../../../../../services/localBackendService";
import _ from "lodash";

const QrReaderTest = () => {
  const [qrCode, setQrCode] = useState(null);
  const [qrReaderConfig, setQrReaderConfig] = useState();

  useEffect(() => {
    // Get QR reader config
    getQrReaderConfig()
      .then((config) => {
        setQrReaderConfig(config?.data);
      })
      .catch(() => {
        btmLog("No se pudo obtener la configuración del lector QR");
      });

    // Turn on QR led
    getLeds().then(ledId => blinkLed(ledId.QR_READER)).catch(console.error);

    //Listen for QR reader
    const qrReaderClient = new QrReaderClient();
    qrReaderClient.onRead(setQrCode);

    return () => {
      getLeds().then(ledId => turnOffLed(ledId.QR_READER)).catch(console.error);
      qrReaderClient.offRead(setQrCode);
    };
  }, []);

  const renderConfig = () => {
    if (!qrReaderConfig) return null;
    const configs = [];
    _.forEach(qrReaderConfig, (value, key) => {
      return configs.push(
        <p className="read-result-value" key={key}>
          {key}: {value}
        </p>
      );
    });
    return configs;
  };

  return (
    <div className={"QrReaderTest"}>
      <h1 className="title">Lector QR</h1>
      <p className="instructions">
        Coloca cualquier código QR frente al lector y la información contenida
        en él se mostrara en pantalla.
      </p>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="read-result">
              <p className="read-result-title">Configuración:</p>
              {renderConfig()}
              <p className="read-result-title">Resultado:</p>
              <p className="read-result-value">
                {qrCode || "No se ha leído ningún código QR"}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <img src={scanWallet} alt="scan" className="scan-image-animated" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrReaderTest;
