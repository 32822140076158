import React, {useCallback, useContext, useEffect, useState} from 'react'
import "./EnterPhoneNumber.scss"
import NumericPad from '../../components/NumericPad/NumericPad'
import {paths} from '../../services/routes/appRoutes'
import {useNavigate} from 'react-router-dom'
import Button from '../../components/Button/Button'
import CircleTimer from '../../components/CircleTimer/CircleTimer'
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import {secondsToTimer} from '../../services/constants'
import {useDispatch, useSelector} from "react-redux";
import {clearSession, setPhoneInfo} from "../../utils/redux/sessionSlice";
import {AlertContext} from "../../components/Alerts/AlertContext";
import {sendSms, sendWa} from "../../services/rayyoClient";
import {asteriskPhoneNumber, generateRandomNumberString} from "../../utils/numberUtils";
import { useTranslation } from 'react-i18next';
import CountryCodes from '../../components/CountryCodes/CountryCodes'
import {countryCodesList} from '../../utils/countryCodesUtils'
import {btmLog} from "../../services/localBackendService";
import {logSessionEvent} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";
import Alert from "../Alert/Alert";
import errorAnimatedIcon from "../../assets/images/info-red-animated.gif";
import { WhatsAppLangTemplates } from '../../i18n/config'
import Checkbox from '../../components/Checkbox/Checkbox'

export default function EnterPhoneNumber() {

    useEffect(() => {
        btmLog('EnterPhoneNumber screen').catch(console.error);
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const {addAlert} = useContext(AlertContext);

    const {sessionId} = useSelector(state => state.session);
    const [phone, setPhone] = useState('');
    const [prefix, setPrefix] = useState('52');
    const [focusSelected, setFocusSelected] = useState('phone')
    const [codesList, setCodesList] = useState([])
    const [showAlertInfo, setShowAlertInfo] = useState(false);
    const [sendWaMessage, setSendWaMessage] = useState(false);

    const buttonEnabled = prefix === "52"? (phone.length === 10) : (phone.length > 7 && prefix.length > 0);

    const filterCountryCodes = useCallback((prefixValue) => {
        let codes = [];
        countryCodesList.forEach(element => {
            if (element.countryCode.startsWith(prefixValue)) {
                codes.push(element);
            }
        });
        setCodesList(codes);
    }, []);

    const onPress = useCallback((value) => {
        if (value === 'Delete') {
            if (focusSelected === 'countryCode') {
                setPrefix('');
                setCodesList([]);
            } else {
                setPhone('');
            }
            return;
        }
        if (value === 'Back') {
          if (focusSelected === 'countryCode') {
            if (prefix.length > 0) {
              const newValue = prefix.substring(0, prefix.length - 1);
              setPrefix(newValue);
              if (newValue.length > 0) {
                filterCountryCodes(newValue);
              } else {
                setCodesList([]);
              }
            }
          } else {
            if (phone.length > 0) {
              const newValue = phone.substring(0, phone.length - 1);
              setPhone(newValue);
            }
          }
          return;
        }
        let maxLength = 16;
        if (focusSelected === 'countryCode') {
            maxLength = 4;
        } else if ( prefix === "52" ) {
            maxLength = 10;
        }
        if (phone.length >= maxLength) {
            return;
        }

        if (focusSelected === 'countryCode') {
            const newValue = prefix + value;
            setPrefix(newValue);
            filterCountryCodes(newValue);
        } else {
            const newValue = phone + value;
            setPhone(newValue)
        }

    }, [filterCountryCodes, focusSelected, phone, prefix]);

    const handlePrefixChange = useCallback((codeSelected) => {
      setPrefix(codeSelected.countryCode);
      setCodesList([]);
      setFocusSelected('phone')
    }, []);

    const handleSendWaMessage = useCallback((e) => {
        setSendWaMessage(e.target.checked);
    }, []);

    const onBack =( reason ) => {
        logSessionEvent(sessionId, sessionEvents.BACK, {from: "EnterPhoneNumber", to: "Home", reason });
        dispatch(clearSession());
        navigate(paths.home);
    };

    const send = useCallback(async () => {
        if (phone.length < 8) {
            addAlert({message: t('Phone number must have'), type: 'error'});
            return;
        }
        const phoneNumber = "+" + prefix + phone;
        const code = generateRandomNumberString(6);
        try {
            const templateId = WhatsAppLangTemplates[i18n.languages[0]]
            const promises = [
                sendSms({phone: phoneNumber, message: `[Cryptobox] ${t('Your verification code is', {code: code})}`})
            ];

            if (sendWaMessage) {
                promises.push(sendWa({phone: phoneNumber, template: templateId, params: {"1": code }}));
            }

            await Promise.all(promises);
            dispatch(setPhoneInfo({  phone: phoneNumber, code}));
        } catch (e) {
            setShowAlertInfo(true);
            return;
        }

        logSessionEvent(sessionId, sessionEvents.SENT_OTP, {phoneNumber: asteriskPhoneNumber(phoneNumber), code });

        navigate(paths.verificationCode);
    }, [phone, prefix, navigate, addAlert, t, dispatch, sessionId, i18n, sendWaMessage]);

    const handleOnFocus = useCallback((type) => {
        setFocusSelected(type);
    }, []);

    return (
        <Layout
            showHeader={true}
            showFooter={true}
            backButtonCallback={()=>onBack("Back button pressed")}
            hideButtons={false}
        >
            <div className='EnterPhoneNumber'>
                <div className='d-flex justify-content-end'>
                    <CircleTimer
                        seconds={secondsToTimer}
                        callback={()=>onBack("Timer finished")}
                    />
                </div>
                <div className='title text-center title-position'>
                    <Title
                        text={t('Enter your phone number')}
                    ></Title>
                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <div className='gradient-border-mask'>
                        <div className='input-number'>
                            <CountryCodes
                                prefix={prefix}
                                codesList={codesList}
                                onSelected={handlePrefixChange}
                                onFocus={() => handleOnFocus('countryCode')}
                            ></CountryCodes>
                            <div
                                className='input-number-separator'
                            >&nbsp;</div>
                            <div
                                className='input-number-phone'
                                onClick={() => handleOnFocus('phone')}
                            >
                                {phone}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='d-flex justify-content-center align-items-center mt-4'>
                      <div className='mr-checkbox'>
                          <Checkbox
                            checked={sendWaMessage}
                            onChange={handleSendWaMessage}
                          ></Checkbox>
                      </div>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="35" width="40" viewBox="0 0 448 512">
                            <path fill="#ffffff" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
                        </svg>
                      </div>
                      <div className='verification-text'>
                        <span>
                          {t('I agree to receive my verification code also via WhatsApp.')}
                        </span>
                      </div>
                </div>

                <div className='d-flex justify-content-center mt-2 ml-3'>
                    <NumericPad
                        onPress={onPress}
                    ></NumericPad>
                </div>

                <div className='d-flex justify-content-center mt-2 ml-3'>
                    <div>
                        <Button
                            onClick={send}
                            type={Button.type.primary}
                            disabled={!buttonEnabled}
                        >
                            {t('Send')}
                        </Button>
                    </div>
                </div>
            </div>
            {!!showAlertInfo && (
              <Alert
                onClose={() => setShowAlertInfo(false)}
                title={t('Code not sent')}
                message={t('We cannot send the verification code at this time. Please try again later.')}
                textSize={'md'}
                icon={errorAnimatedIcon}
              ></Alert>
            )}
        </Layout>
    )
}
