import LocalHttpApi from "./LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();

export async function getNotifications() {
    return localHttpApi.callApi({
        path: "/notifications/get",
        method: "GET",
    });   
}

export async function addNotification({message}) {
    return localHttpApi.callApi({
        path: "/notifications/add",
        method: "POST",
        params: {message}
    });   
}