import { createSlice } from '@reduxjs/toolkit';
import {createSession, finishSession, updateSession} from "../../services/localSessionService";
import {btmLog} from "../../services/localBackendService";

let initialState = {
    sessionId: null,        // uuid
    sessionType: null,      // 'buy' | 'sell' | 'redeem' | 'admin'
    operator: null,         // 'admin' // The username of the operator as string
    userWallet: null,       // 'user-btc-address'
    marketPrice: null,      // MXN per BTC. e.g. 482000.15
    buyPrice: null,         // MXN per BTC. e.g. 462720.144
    sellPrice: null,        // MXN per BTC. e.g. 530200.165
    phoneInfo: null,        // { phone, verificationCode }
    insertedBanknotes: null,//Array of integers per banknote e.g. [ 500,200,100 ]
    requestedAmount: null,  //Integer e.g. 100000
    rayyoAddress: null,     //String e.g. 'bcrt1qxk389z2w4elfehtt3cveqkgmxvdz7dh2r9rdy5'
};

//Preload data for debugging
//buy flow
//initialState =  {"sessionId":"dummy-session-id-not-an-uuid", "sessionType":"buy","userWallet":"bcrt1qf0en83h4ck4lmfn7z25per3ceqsr0wx6ngg43f","marketPrice":488650,"buyPrice":537515,"phoneInfo":{"phone":"+520000000000","code":"781843"},"insertedBanknotes":[20,20]};
//const initialState =  {"sessionId":"18a44ace-3b5e-41c0-9d0e-75efc00eddc3", "sessionType":"buy","marketPrice":447950,"buyPrice":494384.00000000006,"sellPrice":431462.39999999997,"phoneInfo":{"phone":"+525527271986","code":"807413"},"userWallet":"3FZbgi29cpjq2GjdwV8eyHuJJnkLtktZc5"}

const _updateSession = (id, sessionInfo) => {
    updateSession( id, sessionInfo ).catch(e => {
        btmLog(`Error updating session: ${id} - ${JSON.stringify(sessionInfo)} Error: ${e}`).catch(console.error);
        console.log(`Error updating session: ${id} - ${JSON.stringify(sessionInfo)} Error: ${e}`);
    });
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        startSession: (state, action) => {
            const session = action.payload;
             const { sessionType, sessionId, buyPrice, sellPrice, marketPrice } = session;
            state.sessionType = sessionType;
            state.sessionId = sessionId;
            state.buyPrice = buyPrice;
            state.sellPrice = sellPrice;
            state.marketPrice = marketPrice;

            createSession(session).catch(e => {
                btmLog(`Error creating session: ${JSON.stringify(session)} Error: ${e}`).catch(console.error);
                console.log(`Error creating session: ${JSON.stringify(session)} Error: ${e}`);
            });

        },
        setOperator: (state, action) => {
            state.operator = action.payload;
            _updateSession(state.sessionId, { operator: action.payload });
        },
        setUserWallet: (state, action) => {
            state.userWallet = action.payload;
            _updateSession(state.sessionId, { userWallet: action.payload });

        },
        setPhoneInfo: (state, action) => {
            state.phoneInfo = action.payload;
            _updateSession(state.sessionId, { phoneInfo: action.payload });
        },
        setInsertedBanknotes: (state, action) => {
            state.insertedBanknotes = action.payload;
            _updateSession(state.sessionId, { insertedBanknotes: action.payload });
        },
        setRequestedAmount: (state, action) => {
            const {amount, address} = action.payload;
            state.requestedAmount = amount;
            state.rayyoAddress = address;
            _updateSession(state.sessionId, { requestedAmount: action.payload });
        },
        setTicketData: (state, action) => {
            const {address, phone, mxnAmount, btcAmount, buyPrice, sellPrice, id} = action.payload;
            state.ticketData = {address, phone, mxnAmount, btcAmount, buyPrice, sellPrice, id};
            _updateSession(state.sessionId, { ticketData: state.ticketData });
        },
        clearSession: (state) => {

            if( state.sessionId ){
                finishSession(state.sessionId);
            }

            state.sessionId = null;
            state.userWallet = null;
            state.marketPrice = null;
            state.buyPrice = null;
            state.sellPrice = null;
            state.sessionType = null;
            state.verificationCode = null;
            state.insertedBanknotes = null;
            state.requestedAmount = null;
            state.rayyoAddress = null;
            state.kycData = null;
            state.ticketData = null;
        },
        setKycData: (state, action) => {
            state.kycData = action.payload;
            _updateSession(state.sessionId, { kycData: action.payload });
        },
        setTxId: (state, action) => {
            state.txId = action.payload.txId;
            _updateSession(state.sessionId, { txId: action.payload.txId });
        },
    },
});

export const {
    startSession,
    setOperator,
    setUserWallet,
    setPhoneInfo,
    clearSession,
    setInsertedBanknotes,
    setRequestedAmount,
    setKycData,
    setTicketData,
    setTxId,
} = sessionSlice.actions;

export default sessionSlice.reducer;
