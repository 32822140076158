import { validate } from 'bitcoin-address-validation';

//To accept BIP 21 format
export const cleanQrCodeAddress = (addressRead) => {

    let address = addressRead;
    if (address.includes('bitcoin:')) {
        address = address.replace('bitcoin:', '');
    }
    if (address.includes('rayyo_disposal:')) {
        address = address.replace('rayyo_disposal:', '');
    }
    if (address.includes('?')) {
        address = address.split('?')[0];
    }
    return address;
}

export const validateAddress = (address) => {
    return validate(address);
}

export const numberBtcFormat = (number) => {
    return number.toFixed(8);
}
