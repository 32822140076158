import React, { useContext, useState, useEffect } from "react";
import { paths } from "../../../../../services/routes/appRoutes";
import Button from "../../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { saveSetting, getSetting } from "../../../../../services/appSettings";
import { AlertContext } from "../../../../../components/Alerts/AlertContext";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import { getConnectedCameras } from "../../../../../services/localSecurityCameraService";
import "./Drivers.scss";
import { btmLog } from "../../../../../services/localBackendService";
import { logSessionEvent } from "../../../../../services/localSessionService";
import { sessionEvents } from "../../../../../utils/sessionUtils";
import {useSelector} from "react-redux";

export default function Drivers() {
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContext);
  const {sessionId} = useSelector((state) => state.session);

  useEffect(() => {
    btmLog('Drivers screen');
    logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, { screen: "Drivers" });
  }, [sessionId]);

  const [selectedDrivers, setSelectedDrivers] = useState({
    banknoteAcceptor: null,
    banknoteDispenser: null,
    qrReader: null,
    securityCamera: null
  });

  const [cameraDevices, setCameraDevices] = useState({});

  useEffect(() => {

    const getCameraDevices = async () => {
      try {
        const connectedCameras = await getConnectedCameras();
        setCameraDevices(connectedCameras);
      } catch (error) {
        addAlert({
          type: 'error',
          message: `Error getting camera devices: ${error.message}`,
        });
      }
    };

    getCameraDevices()

    const loadSettings = async () => {
      try {
        const savedDrivers = JSON.parse(await getSetting("HARDWARE_DRIVERS"));
        if (savedDrivers) {
          setSelectedDrivers(savedDrivers);
        }
      } catch (e) {
        addAlert({
          type: "error",
          message: `Error al cargar los drivers: ${e.message}`,
        });
      }
    };

    loadSettings();
  }, [addAlert]);

  const handleCheckboxChange = (category, value) => {
    const updatedOptions = {
      ...selectedDrivers,
      [category]: selectedDrivers[category] === value ? null : value,
    };
    setSelectedDrivers(updatedOptions);
  };

  const save = async () => {
    if (
      selectedDrivers.banknoteAcceptor &&
      selectedDrivers.banknoteDispenser &&
      selectedDrivers.qrReader
    ) {
      try {
        await saveSetting("HARDWARE_DRIVERS", JSON.stringify(selectedDrivers));
        navigate(paths.adminMenu);
        addAlert({
          type: "success",
          message: "Drivers guardados correctamente",
        });
      } catch (e) {
        addAlert({
          type: "error",
          message: `Error al guardar drivers: ${e.message}`,
        });
      }
    } else {
      console.log(selectedDrivers)  
      addAlert({ type: "error", message: "Seleccione las opciones faltantes" });
    }
  };
  return (
    <div className="Drivers">
      <h2 className="title" >Selecciona el driver de acuerdo al hardware instalado</h2>

      <div className="spacing">

      <div className="d-flex justify-content-evenly checkboxForm">
        <h2>Aceptador de billetes</h2>

          <div className="form-check d-flex  align-items-center">
            <Checkbox
              checked={selectedDrivers.banknoteAcceptor === "NV200"}
              onChange={() => handleCheckboxChange("banknoteAcceptor", "NV200")}
            />
            <h4 className="label">NV200</h4>
          </div>

          <div className="form-check d-flex  align-items-center">
            <Checkbox
              checked={selectedDrivers.banknoteAcceptor === "NV4000"}
              onChange={() => handleCheckboxChange("banknoteAcceptor", "NV4000")}
            />
            <h4 className="label">NV4000</h4>
          </div>

          <div className="form-check d-flex align-items-center">
            <Checkbox
              checked={selectedDrivers.banknoteAcceptor === "SCNRL8328"}
              onChange={() =>
                handleCheckboxChange("banknoteAcceptor", "SCNRL8328")
              }
            />

            <h4 className="label">SCNRL8328</h4>
          </div>
        </div>
      </div>

      <div className="spacing">
      <div className="d-flex justify-content-evenly checkboxForm">
        <h2>Dispensador de billetes</h2>

          <div className="form-check d-flex align-items-center">
            <Checkbox
              checked={selectedDrivers.banknoteDispenser === "SMD3100R2"}
              onChange={() =>
                handleCheckboxChange("banknoteDispenser", "SMD3100R2")
              }
            />
            <h4 className="label">SMD3100R2</h4>
          </div>

          <div className="form-check d-flex  align-items-center">
            <Checkbox
              checked={selectedDrivers.banknoteDispenser === "NV4000"}
              onChange={() => handleCheckboxChange("banknoteDispenser", "NV4000")}
            />
            <h4 className="label">NV4000</h4>
          </div>

          <div className="form-check d-flex align-items-center">
            <Checkbox
              checked={selectedDrivers.banknoteDispenser === "SCNRL8328"}
              onChange={() =>
                handleCheckboxChange("banknoteDispenser", "SCNRL8328")
              }
            />
            <h4 className="label">SCNRL8328</h4>
          </div>
        </div>
      </div>

      <div className="spacing">
        <div className="d-flex justify-content-evenly checkboxForm">

        <h2>Lector QR</h2>
          <div className="form-check d-flex align-items-center">
            <Checkbox
              checked={selectedDrivers.qrReader === "ZD7780B"}
              onChange={() => handleCheckboxChange("qrReader", "ZD7780B")}
            />
            <h4 className="label">ZD7780B</h4>
          </div>

          <div className="form-check d-flex align-items-center">
            <Checkbox
              checked={selectedDrivers.qrReader === "XZG004"}
              onChange={() => handleCheckboxChange("qrReader", "XZG004")}
            />
            <h4 className="label">XZG004</h4>
          </div>

          <div className="form-check d-flex align-items-center">
            <Checkbox
              checked={selectedDrivers.qrReader === "QP1609"}
              onChange={() => handleCheckboxChange("qrReader", "QP1609")}
            />
            <h4 className="label">QP1609</h4>
          </div>
        </div>
      </div>

      <div className="spacing">
      <div className="d-flex justify-content-evenly checkboxForm">

      <h2>Cámara de seguridad</h2>
        {Object.entries(cameraDevices).map(([driverName, devicePath]) => (
          <div key={driverName} className="form-check d-flex align-items-center">
            <Checkbox
              checked={selectedDrivers.securityCamera === devicePath}
              onChange={() => handleCheckboxChange('securityCamera', devicePath)}
            />
            <h4 className="label">{driverName}</h4>
          </div>
        ))}
      </div>
    </div>

      <div className="d-flex align-items-center">
        <Button  onClick={() => save()}>
          Guardar
        </Button>
      </div>
    </div>    
  );
}
