import PagesList from "../../components/PagesList/PagesList";
import BuyBitcoin from "../../scenes/BuyBitcoin/BuyBitcoin";
import BuyBitcoinConfirm from "../../scenes/BuyBitcoinConfirm/BuyBitcoinConfirm";
import EnterPhoneNumber from "../../scenes/EnterPhoneNumber/EnterPhoneNumber";
import Home from "../../scenes/Home/Home";
import PrintTicket from "../../scenes/PrintTicket/PrintTicket";
import ScanWallet from "../../scenes/ScanWallet/ScanWallet";
import Response from "../../scenes/Response/Response";
import ResponseQrCode from "../../scenes/Response/ResponseQrCode";
import ResponseTransaction from "../../scenes/Response/ResponseTransaction";
import SellBitcoin from "../../scenes/SellBitcoin/SellBitcoin";
import SendBitcoin from "../../scenes/SendBitcoin/SendBitcoin";
import TakeYourTicket from "../../scenes/TakeYourTicket/TakeYourTicket";
import VerificationCode from "../../scenes/VerificationCode/VerificationCode";
import MockControl from "../../scenes/MockControl/MockControl";
import Boot from "../../scenes/Boot/Boot";
import RegisterBtm from "../../scenes/RegisterBtm/RegisterBtm";
import ScanTicket from "../../scenes/ScanTicket/ScanTicket";
import Login from "../../scenes/Admin/Login/Login";
import AdminMenu from "../../scenes/Admin/AdminMenu/AdminMenu";
import ManageCashbox from "../../scenes/Admin/ManageCashbox/ManageCashbox";
import RefillCashbox from "../../scenes/Admin/RefillCashbox/RefillCashbox";
import BanknoteEvents from "../../scenes/Admin/BanknoteEvents/BanknoteEvents";
import Terms from "../../scenes/Terms/Terms";
import IncreaseLimit from "../../scenes/IncreaseLimit/IncreaseLimit";
import TransactionLimit from "../../scenes/TransactionLimit/TransactionLimit";
import TransactionLimitAlert from "../../scenes/TransactionLimitAlert/TransactionLimitAlert";
import Success from "../../scenes/Success/Success";
import SuccessRedemption from "../../scenes/SuccessRedemption/SuccessRedemption";
import Alert from "../../scenes/Alert/Alert";
import HardwareTest from "../../scenes/Admin/HardwareTest/HardwareTest";
import SetTimeZone from "../../scenes/Admin/SetTimeZone/SetTimeZone";
import TransactionVerification from "../../scenes/TransactionVerification/TransactionVerification";
import ManualRefillCashbox from "../../scenes/Admin/ManualRefillCashbox/ManualRefillCashbox";
import VerifyIdentity from "../../scenes/VerifyIdentity/VerifyIdentity";
import HardwareDrivers from "../../scenes/Admin/HardwareDrivers/HardwareDrivers";

export const paths = {
  boot: "/boot",
  home: "/home",
  register: "/admin/register",
  buy: "/buy",
  buyConfirm: "/buy-confirm",
  sell: "/sell",
  scanWallet: "/scan-wallet",
  scanTicket: "/scan-ticket",
  enterPhoneNumber: "/enter-phone-number",
  verificationCode: "/verification-code",
  response: "/response",
  takeYourTicket: "/take-your-ticket",
  sendBitcoin: "/send-bitcoin",
  printTicket: "/print-ticket",
  pagesList: "/list",
  responseQrCode: "/response-qr-code",
  responseTransaction: "/response-transaction",
  mockControl: "/mock-control",
  login: "/login",
  adminMenu: "/admin/menu",
  manageCashbox: "/admin/manage-cashbox",
  refillCashbox: "/admin/refill-cashbox",
  manualRefillCashbox: "/admin/manual-refill-cashbox",
  banknoteEvents: "/admin/banknote-events",
  setTimeZone: "/admin/set-time-zone",
  terms: "/terms",
  increaseLimit: "/increase-limit",
  transactionLimit: "/transaction-limit",
  transactionLimitAlert: "/transaction-limit-alert",
  transactionVerification: "/transaction-verification",
  success: "/success",
  successRedemption: "/success-redemption",
  alert: "/alert",
  hardwareTest: "/admin/hardware-test",
  hardwareDrivers: "/admin/hardware-drivers",
  verifyIdentity: "/verify-identity",
};

const getAppRoutes = () => {
  let routes = [];

  // Boot
  routes.push({ path: paths.boot, Component: Boot });
  // Home
  routes.push({ path: paths.home, Component: Home });

  // All flows
  routes.push({ path: paths.enterPhoneNumber, Component: EnterPhoneNumber });
  routes.push({ path: paths.verificationCode, Component: VerificationCode });
  // Buy  routes
  routes.push({ path: paths.scanWallet, Component: ScanWallet });
  routes.push({ path: paths.buy, Component: BuyBitcoin });
  routes.push({ path: paths.buyConfirm, Component: BuyBitcoinConfirm });
  routes.push({ path: paths.takeYourTicket, Component: TakeYourTicket });

  // Sell  routes
  routes.push({ path: paths.sell, Component: SellBitcoin });
  routes.push({ path: paths.sendBitcoin, Component: SendBitcoin });

  // Redeem  routes
  routes.push({ path: paths.scanTicket, Component: ScanTicket });

  // Modal views
  routes.push({ path: paths.response, Component: Response });
  routes.push({
    path: paths.responseTransaction,
    Component: ResponseTransaction,
  });
  routes.push({ path: paths.responseQrCode, Component: ResponseQrCode });
  routes.push({ path: paths.printTicket, Component: PrintTicket });

  // Terms
  routes.push({ path: paths.terms, Component: Terms });

  // Increase Limit
  routes.push({ path: paths.increaseLimit, Component: IncreaseLimit });

  // Alert
  routes.push({ path: paths.alert, Component: Alert });

  // Transaction Limit
  routes.push({ path: paths.transactionLimit, Component: TransactionLimit });

  // Transaction Limit Alert
  routes.push({
    path: paths.transactionLimitAlert,
    Component: TransactionLimitAlert,
  });

  // Transaction Verification
  routes.push({
    path: paths.transactionVerification,
    Component: TransactionVerification,
  });

  // For development
  routes.push({ path: paths.pagesList, Component: PagesList });
  routes.push({ path: paths.mockControl, Component: MockControl });

  // Success Screen
  routes.push({ path: paths.success, Component: Success });
  routes.push({ path: paths.successRedemption, Component: SuccessRedemption });

  // Admin routes
  routes.push({ path: paths.login, Component: Login });
  routes.push({ path: paths.adminMenu, Component: AdminMenu });
  routes.push({ path: paths.register, Component: RegisterBtm });
  routes.push({ path: paths.manageCashbox, Component: ManageCashbox });
  routes.push({ path: paths.refillCashbox, Component: RefillCashbox });
  routes.push({
    path: paths.manualRefillCashbox,
    Component: ManualRefillCashbox,
  });
  routes.push({ path: paths.banknoteEvents, Component: BanknoteEvents });
  routes.push({ path: paths.setTimeZone, Component: SetTimeZone });
  routes.push({ path: paths.hardwareTest, Component: HardwareTest });
  routes.push({ path: paths.hardwareDrivers, Component: HardwareDrivers });
  //Verify Identity
  routes.push({path: paths.verifyIdentity, Component: VerifyIdentity})

  return routes;
};

export default getAppRoutes;
