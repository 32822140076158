import LocalWsApi from "./LocalWsApi";

const controllerPrefix = "routeListener/";

export default class RouteListenerService {
    constructor() {
        this.wsApi = LocalWsApi.getInstance();
        this.listeners = [];
        this.listenForRouteRequest();
    }

    listenForRouteRequest() {
        this.wsApi.subscribe(controllerPrefix + 'requestCurrentRoute', (ackCallback) => {
            const currentRoute = window.location.pathname;
            ackCallback(currentRoute)
        });
    }    

    static getInstance() {
        if (!RouteListenerService.instance) {
            RouteListenerService.instance = new RouteListenerService();
        }
        return RouteListenerService.instance;
    }
}
