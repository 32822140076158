import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Rate from "../../components/Rate/Rate";
import { paths } from "../../services/routes/appRoutes";
import "./ScanWallet.scss";
import scanWallet from "../../assets/images/scan_wallet.gif";
import scanArrow from "../../assets/images/scan_arrow.gif";
import CircleTimer from "../../components/CircleTimer/CircleTimer";
import InstructionsBox from "../../components/InstructionsBox/InstructionsBox";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { secondsToTimer } from "../../services/constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserWallet } from "../../utils/redux/sessionSlice";
import QrReaderClient from "../../services/hardware/QrReaderClient";
import { AlertContext } from "../../components/Alerts/AlertContext";
import { cleanQrCodeAddress, validateAddress } from "../../utils/btcUtils";
import { btmLog } from "../../services/localBackendService";
import { useTranslation } from "react-i18next";
import {
  blinkLed,
  getLeds,
  turnOffLed,
} from "../../services/hardware/ledsService";
import errorAnimatedIcon from "../../assets/images/info-red-animated_1.gif";
import Alert from "../Alert/Alert";
import {logSessionEvent} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";

export default function ScanWallet() {
  useEffect(() => {
    btmLog("ScanWallet screen").catch(console.error);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { sessionId, buyPrice } = useSelector((state) => state.session);
  const { addAlert } = useContext(AlertContext);
  const [showInvalidCodeAlert, setShowInvalidCodeAlert] = useState(false);

  useEffect(() => {
    getLeds().then(ledId => blinkLed(ledId.QR_READER)).catch(console.error);
    return () => {
      getLeds().then(ledId => turnOffLed(ledId.QR_READER)).catch(console.error);
    };
  }, []);

  useEffect(() => {
    // Set up the QR reader event listener
    const handleQrRead = (data) => {
      const address = cleanQrCodeAddress(data);
      if (!validateAddress(address)) {
        setShowInvalidCodeAlert(true);
        logSessionEvent(sessionId, sessionEvents.QR_READ, { value: address, valid: false });
        return;
      }

      logSessionEvent(sessionId, sessionEvents.QR_READ, { value: address, valid: true });

      dispatch(setUserWallet(address));
      navigate(paths.buy);
    };

    const qrReaderClient = new QrReaderClient();
    qrReaderClient.onRead(handleQrRead);

    return () => {
      qrReaderClient.offRead(handleQrRead);
    };
  }, [dispatch, navigate, addAlert, t, sessionId]);

  const onBack = (reason) => {
    btmLog("Finishing session with reason: " + reason);
    logSessionEvent(sessionId, sessionEvents.BACK, {from: "ScanWallet", to: "Home", reason });
    navigate(paths.home);
  };

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      backButtonCallback={() => {
        onBack("Scan wallet screen back button.");
      }}
      hideButtons={false}
    >
      <div className="ScanWallet">
        <div className="title text-center mt-5">
          <Title text={t("Scan your wallet")}></Title>
        </div>
        <div className="mt-custom d-flex justify-content-between">
          <Rate text={t("Buy")} value={buyPrice} />
          <CircleTimer
            seconds={secondsToTimer}
            callback={() => {
              onBack("Scan wallet screen timer.");
            }}
          />
        </div>
        <div className="d-flex justify-content-between content">
          <div>
            <InstructionsBox
              title={t("Instructions")}
              list={[
                t("In your application, select the option to receive"),
                t("Make sure the QR code is fully visible on the screen"),
                t(
                  "Use the reader at the bottom left of the BTM to scan the QR code of your Wallet"
                ),
              ]}
              boxClass="box-instructions"
              titleClass="box-instructions-title"
            ></InstructionsBox>
          </div>
          <div className="scan-image">
            <img src={scanWallet} alt="scan" className="scan-image-animated" />
            <img src={scanArrow} alt="Arrow" className="arrow-image-animated" />
          </div>
        </div>
        {showInvalidCodeAlert && (
          <Alert
            onClose={() => setShowInvalidCodeAlert(false)}
            title={t("Invalid code")}
            message={t(
              "Check the scanned QR. If the problem persists, contact support."
            )}
            icon={errorAnimatedIcon}
          ></Alert>
        )}
      </div>
    </Layout>
  );
}
