import "react-simple-keyboard/build/css/index.css";
import './App.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from 'react-redux';
import getAppRoutes from './services/routes/appRoutes';
import './assets/fonts/fonts.scss';
import { useEffect } from "react";
import LocalWsApi from "./services/LocalWsApi";
import store from "./utils/redux/store";
import {AlertProvider} from "./components/Alerts/AlertContext";
import Alerts from "./components/Alerts/Alerts";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import CheckBtmStatusService from "./services/CheckBtmStatusService";
import RouteListenerService from "./services/RouteListenerService";

function App() {
    useEffect(() => {
        LocalWsApi.getInstance();
        CheckBtmStatusService.getInstance().onStatusChange( s=>console.log('[App.js]', s) );
        RouteListenerService.getInstance();
    }, []);

    const routes = getAppRoutes();

    return (
        <Provider store={store}>
            <ErrorBoundary>
                <AlertProvider>
                    <div className='App'>
                        <Alerts />
                        <Router>
                            <Routes>
                                {
                                    routes.map(({ path, Component }) =>
                                        <Route
                                            key={path}
                                            path={path}
                                            element={<Component />}
                                        />
                                    )
                                }
                                <Route path="*" element={<Navigate replace to={routes[0].path} />} />
                            </Routes>
                        </Router>
                    </div>
                </AlertProvider>
            </ErrorBoundary>
        </Provider>
    );
}

export default App;
