import React, { useContext, useEffect, useState } from "react";

import "./ManualRefillCashbox.scss";
import Layout from "../../../components/Layout/Layout";
import Button from "../../../components/Button/Button";
import { paths } from "../../../services/routes/appRoutes";
import { useNavigate } from "react-router-dom";
import { btmLog } from "../../../services/localBackendService";
import TextInput from "../../../components/TextInput/TextInput";
import { transactionTypes } from "../../../utils/transactionUtils";
import { AlertContext } from "../../../components/Alerts/AlertContext";
import { saveManualCashboxRefill } from "../../../services/frontBanknoteService";
import {logSessionEvent} from "../../../services/localSessionService";
import {sessionEvents} from "../../../utils/sessionUtils";
import {useSelector} from "react-redux";
import { getSetting, settingKey } from "../../../services/appSettings";
import { printAdminTransactionTicket } from "../../../services/frontBanknoteService";
import { addNotification } from "../../../services/frontNotificationService";
import BanknoteRecyclerClient from "../../../services/hardware/BanknoteRecylcerClient";
import Alert from "../../Alert/Alert";
import loading from '../../../assets/images/loading.gif'
const ManualRefillCashbox = () => {
  const { addAlert } = useContext(AlertContext);
  const {sessionId} = useSelector((state) => state.session);
  const [disableButton, setDisableButton] = useState(false);
  const [operationInProcess, setOperationInProcess] = useState(false);
  const [drivers, setDrivers] = useState()
  const [boxValues, setBoxValues] = useState([
    { box: 1, denomination: 500, value: 0 },
    { box: 2, denomination: 500, value: 0 },
  ]);
  const [nv4000box, setNv4000box] = useState() 
  const operator = useSelector(state => state.auth.operator);
   
  useEffect(() => {
    btmLog("RefillCashbox screen");
    logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, { screen: "ManualRefillCashbox" });
  }, [sessionId]);

  useEffect(() => {
    const fetchDrivers = async () => {
      const savedDrivers = JSON.parse(await getSetting("HARDWARE_DRIVERS"));
      if (savedDrivers) {
        setDrivers(savedDrivers);
      }
    };
  
    fetchDrivers();
  }, []);

  const navigate = useNavigate();

  const handleNv4000Change = (event) => {
    setNv4000box(event)
  }

  const handleBoxChange = (boxNumber, event) => {
    setBoxValues((prevBoxValues) =>
      prevBoxValues.map((box) =>
        box.box === boxNumber ? { ...box, value: event } : box
      )
    );
  };

  const handleFinish = async () => {
    try {
      const parsedBoxValues = boxValues.map((item) => {
        if (isNaN(parseInt(item.value))) {
          item.value = 0;
        }
        return { ...item, value: parseInt(item.value) };
      });
      saveManualCashboxRefill(parsedBoxValues);

      logSessionEvent(sessionId, sessionEvents.MANUAL_REFILL, { boxValues: parsedBoxValues });

      addAlert({
        type: "success",
        message: "Balances guardados correctamente",
      });
    } catch (e) {
      addAlert({
        type: "error",
        message: "Error al guardar" + e.message,
      });
      const errStr = "ManualRefillCashbox - fail to finish. " + e.message || "Unknown error";
      logSessionEvent(sessionId, sessionEvents.ERROR, {step: "ManageCashbox", critical: false, error: errStr });
      btmLog(errStr);

    } finally {
      navigate(paths.manageCashbox);
    }
  };

  const handleNv4000Save = async() => {
    const type= transactionTypes.ADMIN_INSERT;
    const recyclingDenomination = JSON.parse(await getSetting(settingKey.RECYCLING_BANKNOTES))    
    setDisableButton(true)
    
    try{
      const recycler = new BanknoteRecyclerClient();
      setOperationInProcess(true)
      const response = await recycler.replenishCassette(nv4000box)      
      if(response?.success || response?.status?.includes("HALTED")){
        setOperationInProcess(false)
        if(response?.status?.includes("HALTED") && !response.possibleNoteStucked){
          const message = "Se recibió el evento HALTED pero se realizó el reabastecimiento"
          addAlert({
            type: "success",
            message: message,
          });
          await addNotification({message: message})          
        }else if(response?.status?.includes("HALTED") && response.possibleNoteStucked){
          const message = "Posible billete atorado en dock trasero del cassette de reabastecimiento"
          addAlert({
            type: "error",
            message: message,
          });
          await addNotification({message: message})
        } else if(response?.wrongAmountRegistered){
          const message = `El número de billetes registrado no coincide con el conteo interno. Registrados: ${response.notesRegistered} Almacenados: ${response.insertedNotes}`
          addAlert({
            type: "error",
            message: message,
          });
          await addNotification({message: message})
        }else{
          const message = "Reabastecimiento exitoso"
          addAlert({
            type: "success",
            message: message,
          });
          await addNotification({message: message})
        }
        const insertedNotes = {[recyclingDenomination[0]]: response.insertedNotes}
        const extraInfo = {notesRegistered: response.notesRegistered, notesReplenished:response.notesReplenished, notesRejected:response.notesRejected }
        await printAdminTransactionTicket({type, notes: insertedNotes, operator, extraInfo})
        navigate(paths.adminMenu);
      }else{
        setOperationInProcess(false)
        setDisableButton(false)
        addAlert({
          type: "error",
          message: `Error al guardar: ${response?.status}`,
        });
      }
    }catch(e){
      setOperationInProcess(false)
      setDisableButton(false)
      addAlert({
        type: "error",
        message: e.message,
      });
    }
  }

  return (
    <Layout className={"ManualRefillCashbox"} showFooter={false}>
      <div className="content">
        {drivers?.banknoteDispenser === "NV4000" && 
         <>
          <h1>Reabastecimiento para dispensadora NV4000</h1>
          <h5>Ingrese el número de billetes reabastecidos</h5>
          <div className="d-flex align-items-center justify-content-around refill-box">
          <TextInput
            type="text"
            id={`cassette`}
            onChange={(e) => handleNv4000Change(e)}
            value={nv4000box}
          />
          </div>

          <div className="button-container">
            <Button onClick={handleNv4000Save} disabled={disableButton} className="button-continue">
              Guardar
            </Button>
          </div>
         </>
        }
        {drivers?.banknoteDispenser === "SMD3100R2" && (
          <>
            <h1>Refill manual para dispensadora de billetes SMD</h1>
            {boxValues.map((box) => (
              <div
                className="d-flex align-items-center justify-content-around refill-box"
                key={box.box}
              >
                <h2>{`Caja ${box.box} - Denominación $${box.denomination}`}</h2>
  
                <TextInput
                  type="text"
                  id={`box${box.box}Amount`}
                  onChange={(e) => handleBoxChange(box.box, e)}
                  value={box.value}
                />
              </div>
            ))}
  
            <div className="button-container">
              <Button onClick={handleFinish} className="button-continue">
                Guardar
              </Button>
            </div>
          </>
        )}

        <Button
          type={Button.type.secondary}
          className="return-button"
          onClick={() => navigate(paths.manageCashbox)}
        >
          Regresar
        </Button>

        {
          operationInProcess &&
          <Alert
          onClose={()=>{}}
          title={"Reabastecimiento en proceso"}
          message={"Espera para recibir un aviso o el ticket de dotación"}
          icon={loading}
          />            
        }

      </div>
    </Layout>
  );
  
};

export default ManualRefillCashbox;
