import React, {useCallback, useEffect, useState} from 'react'
import Rate from '../../components/Rate/Rate'
import Wallet from '../../components/Wallet/Wallet'
import "./BuyBitcoin.scss"
import insertCash from "../../assets/images/insert_cash.svg"
import { paths } from '../../services/routes/appRoutes'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import CircleTimer from '../../components/CircleTimer/CircleTimer'
import InstructionsBox from '../../components/InstructionsBox/InstructionsBox'
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import { secondsToTimer } from '../../services/constants'
import {useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import CurrentLimit from '../../components/CurrentLimit/CurrentLimit'
import {formatBankNotes, formatMoney} from '../../utils/numberUtils'
import {btmLog} from "../../services/localBackendService";
import BanknoteRecyclerClient from "../../services/hardware/BanknoteRecylcerClient";
import {logSessionEvent} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";

export default function BuyBitcoin() {
  const [allowedNotes, setAllowedNotes] = useState([]);

  useEffect(() => {
    btmLog('BuyBitcoin screen').catch(console.error);
    const recyclerClient = new BanknoteRecyclerClient();
    recyclerClient.getAcceptedBanknotes().then(setAllowedNotes)
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {sessionId, buyPrice, userWallet, kycData} = useSelector((state) => state.session);

  const currentLimit = kycData.amountLimitRemaining;
  const isKYC = kycData.kycStatus === 'verified';

  const onBack = useCallback(() => {
    logSessionEvent(sessionId, sessionEvents.BACK, {from: "BuyBitcoin", to: "ScanWallet", reason: "Back button pressed" });
    navigate(paths.scanWallet);
  }, [navigate, sessionId]);

  const onContinue = useCallback(() => {
    navigate(paths.buyConfirm);
  }, [navigate])

  const onTimeout = useCallback(() => {
    logSessionEvent(sessionId, sessionEvents.BACK, {from: "BuyBitcoin", to: "Home", reason: "Timer finished" });
    navigate(paths.home);
  },[navigate, sessionId]);

  const onIncrease = useCallback(() => {
    navigate(paths.increaseLimit, {state: {backRoute: -1, nextRoute: paths.buyConfirm}})
  }, [navigate]);

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      backButtonCallback={onBack}
      hideButtons={false}
    >
      <div className='BuyBitcoin'>
        <div className='text-center title'>
          <Title
            text={t('Before starting')}
          ></Title>
        </div>
        <div className='mt-4 d-flex justify-content-between'>
          <Rate
            text={t('Buy')}
            value={buyPrice}
          />
          <CircleTimer
            seconds={secondsToTimer}
            callback={onTimeout}
          ></CircleTimer>
        </div>
        <div className='d-flex justify-content-center wallet-section'>
          <Wallet address={userWallet} />
        </div>
        <div className='d-flex justify-content-center'>
          <div className='current-limit'>
            <CurrentLimit
              showIcon={true}
              showButton={!isKYC}
              onIncrease={onIncrease}
            >
              {
                !isKYC &&
                t('Current limit is: ', {amount: '$' + formatMoney(currentLimit)})
              }
              {
                isKYC &&
                t('Current transaction limit is: ', {amount: '$' + formatMoney(currentLimit)})
              }
            </CurrentLimit>
          </div>
        </div>
        <div className='d-flex content-section'>
          <div className='insert-cash'>
            <img src={insertCash} alt="insert-cash" />
          </div>
          <div>
            <InstructionsBox
              title={t('Buy terms')}
              list={[
                <strong>{t('The first bill entered must be $500')}</strong>,
                t('The minimum purchase is $500.00 MXN'),
                t('Bills Accepted: ') + formatBankNotes(allowedNotes),
                t('This BTM does not give change'),
                t('Maximum amount per transaction of $12,000.00 MXN', {amount: '$' + formatMoney(currentLimit)}),
                t('Maximum amount of transactions per day is $30,000 MXN', {period: !isKYC ? t('month') : t('day'), amount: !isKYC ? '$20,000' : '$30,000'})
              ]}
              boxClass="box-instructions"
              titleClass="conditions-title"
            ></InstructionsBox>
          </div>
        </div>
        <div className='d-flex buy-button-container justify-content-center mt-4 border-custom'>
          <div>
            <Button
              onClick={onContinue}
            >
              {t('Continue')}
            </Button>
          </div>
        </div>
      </div>
    </Layout>

  )
}
