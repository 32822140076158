import React from 'react';
import Layout from '../../../components/Layout/Layout';
import TabsContainer from '../../../components/TabsContainer/TabsContainer';
import LedConfig from './components/LedConfig/LedConfig';
import Drivers from './components/Drivers/Drivers';
import Button from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../services/routes/appRoutes';
import './HardwareDrivers.scss'

const HardwareDrivers = () => {
    const navigate = useNavigate();
    return(
        <Layout className={'HardwareDrivers'} showFooter={false}>

        <TabsContainer
            tabs={[
                {title: "Drivers",
                    content: <Drivers/>
                },
                {title: "Configuración de leds",
                 content: <LedConfig/>
                }
            ]}
        />
        
        <Button type={Button.type.secondary} className='return-button' onClick={()=>navigate(paths.adminMenu)}>
        Regresar
        </Button>

        </Layout>
    )
}

export default HardwareDrivers;