import React, { useCallback, useContext, useEffect, useState } from "react";
import "./ManageCashbox.scss";
import Layout from "../../../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyCashbox,
  getBanknotesBalance,
  printConsultTicket,
} from "../../../services/frontBanknoteService";
import BalanceRow from "./BalanceRow";
import { AlertContext } from "../../../components/Alerts/AlertContext";
import { moneyFormatter } from "../../../services/currencyUtils";
import Button from "../../../components/Button/Button";
import { paths } from "../../../services/routes/appRoutes";
import { useNavigate } from "react-router-dom";
import { btmLog } from "../../../services/localBackendService";
import { createTransaction } from "../../../services/localTransactionService";
import { transactionTypes } from "../../../utils/transactionUtils";
import {sessionEvents} from "../../../utils/sessionUtils";
import {logSessionEvent} from "../../../services/localSessionService";
import { getSetting } from "../../../services/appSettings";
import Modal from "../../../components/Modal/Modal";

const ManageCashbox = () => {

  const dispatch = useDispatch();
  const { addAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const operator = useSelector((state) => state.auth.operator);
  const [banknotesBalance, setBanknotesBalance] = useState([]);
  const {sessionId} = useSelector((state) => state.session);
  const [manualRefill, setManualRefill] = useState()

  const [drivers, setDrivers ] = useState();
  const [showCassetteModal, setShowCassetteModal] = useState(false)

  useEffect(() => {
    btmLog("ManageCashbox screen " + sessionId);
    logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, { screen: "ManageCashbox" });
  }, [sessionId]);

  useEffect(()=>{
    const fetchDrivers = async ()=>{
      const drivers  =JSON.parse(await getSetting("HARDWARE_DRIVERS"))
      setDrivers(drivers)
      const requireManualRefill = drivers.banknoteAcceptor === "NV4000" || drivers.banknoteAcceptor === "SMD3100R2"
      setManualRefill(requireManualRefill)
    }
    fetchDrivers()
  },[])

  const loadBalances = useCallback(() => {
    getBanknotesBalance()
      .then(({ data }) => {
        data.sort((a, b) => a.denomination - b.denomination);
        setBanknotesBalance(data);
      })
      .catch((e) => {
        btmLog("ManageCashbox - Error getting balance: " + e);
        addAlert({
          message: "Error al obtener balance. " + e.message,
          type: "error",
        });
      });
  }, [addAlert]);

  useEffect(() => {
    loadBalances();
  }, [loadBalances, dispatch]);

  const getTotal = () => {
    return banknotesBalance.reduce(
      (acc, b) => acc + b.count * b.denomination,
      0
    );
  };

  const clearBalanceAnimation = () => {
    setBanknotesBalance((banknotesBalance) => {
      let count = 0;
      const newBalances = [];
      banknotesBalance.forEach((b) => {
        if (b.count > 0) {
          newBalances.push({
            denomination: b.denomination,
            count: b.count - (b.count > 20 ? 10 : 1),
            boxType: b.boxType,
          });
          count++;
        } else {
          newBalances.push(b);
        }
      });

      if (count) {
        setTimeout(clearBalanceAnimation, 40);
      }
      return newBalances;
    });
  };

  const empty = () => {
    if (!getTotal()) {
      addAlert({ message: "La caja ya está vacía", type: "warning" });
      return;
    }

    console.log(1, sessionId);

    btmLog("ManageCashbox - Start emptyCashbox");

    emptyCashbox({ operator })
      .then(() => {

        logSessionEvent(sessionId, sessionEvents.EMPTY_CASHBOX, { notes: banknotesBalance });

        const totalBalance = banknotesBalance.reduce(
          (acc, b) => acc + b.count * b.denomination,
          0
        );
        const totalNotes = banknotesBalance.reduce(
          (acc, b) => acc + b.count,
          0
        );
        clearBalanceAnimation();
        return createTransaction({
          amount: totalBalance,
          type: transactionTypes.ADMIN_WITHDRAWAL,
          username: operator.username,
          notes: totalNotes,
          txId: sessionId,
        });
      })
      .then(() => {
        addAlert({ message: "Caja vaciada correctamente", type: "success" });
      })
      .catch((e) => {
        addAlert({
          message: "Error al vaciar caja. " + e.message,
          type: "error",
        });
        const errStr = 'Error emptying cashbox: ' + e?.message || e;
        logSessionEvent(sessionId, sessionEvents.ERROR, {step: "ManageCashbox", critical: false, error: errStr });
        btmLog(errStr);
        loadBalances();
      }); 
  }; 

  const confirmEmptyAction = () => {
    if(drivers.banknoteAcceptor === "NV4000"){
      setShowCassetteModal(true)
    }else{
      empty()
    }
  }

  const printTicket = useCallback(() => {

    logSessionEvent(sessionId, sessionEvents.PRINT_BALANCE, { banknotesBalance });

    printConsultTicket({
      operator,
    })
      .then(() => {
        addAlert({
          message: "Consulta realizada correctamente",
          type: "success",
        });
      })
      .catch((e) => {
        addAlert({
          message: "Error al imprimir ticket de consulta. " + e.message,
          type: "error",
        });
        btmLog("ManageCashbox - Error in printConsultTicket: " + e);
      });
  }, [addAlert, operator, banknotesBalance, sessionId]);

  const refillCashBoxClick = (type) => {

    if (type === "auto") {
      navigate(paths.refillCashbox);
    } else if (type === "manual") {
      navigate(paths.manualRefillCashbox);
    }
  };

  return (
    <Layout className={"ManageCashbox"} showFooter={false}>
      <div className="content">
        <div>
          <h3>{operator?.name}, este es el saldo actual.</h3>
        </div>

        <div className={"banknotes-table"}>
          {banknotesBalance.map((balance, i) => (
            <BalanceRow balance={balance} key={i} />
          ))}
        </div>

        <div className="total">Total: ${moneyFormatter(getTotal())}</div>

        <div className="buttons">
          <Button onClick={confirmEmptyAction} className="button-continue">
            Corte
          </Button>
          {!manualRefill &&
          <Button
            onClick={() => refillCashBoxClick("auto")}
            className="button-continue"
          >
            Dotación
          </Button>
          }
          {manualRefill && 
          <Button
            onClick={() => refillCashBoxClick("manual")}
            className="button-continue"
          >
            
            Dotación Manual
          </Button>
          }
          <Button onClick={() => printTicket()} className="button-continue">
            Consulta
          </Button>
        </div>

        <Button
          type={Button.type.secondary}
          className="return-button"
          onClick={() => navigate(paths.adminMenu)}
        >
          Regresar
        </Button>

        {showCassetteModal &&
          <Modal>

          <div className="cassette-modal">
            <div className="cassette-modal-content">
              <div className="cassette-modal-title">Atención</div>
              <div className="cassette-modal-message">Antes de continuar, asegúrate de haber vaciado los billetes del cassette de reabastecimiento
                y de haberlo colocado en su lugar. 
              </div>
              <div className="cassette-modal-buttons">
                <Button type={Button.type.primary} onClick={()=>{setShowCassetteModal(false); empty()}}>Continuar</Button>
                <Button type={Button.type.secondary} onClick={()=>{setShowCassetteModal(false)}}>Cancelar</Button>
              </div>
            </div>

          </div>

          </Modal>
        }


      </div>
    </Layout>
  );
};

export default ManageCashbox;
