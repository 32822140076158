import LocalHttpApi from "../LocalHttpApi";
import {getSetting} from "../../services/appSettings" 

const localHttpApi = LocalHttpApi.getInstance();

export async function turnOffLed(ledId) {
    return localHttpApi.callApi({
        path: "/leds/turnOff",
        method: "POST",
        params: { ledId },
    });
}

export async function blinkLed(ledId) {
    return localHttpApi.callApi({
        path: "/leds/blink",
        method: "POST",
        params: { ledId },
    });
}

export async function turnOnLed(ledId) {
    return localHttpApi.callApi({
        path: "/leds/turnOn",
        method: "POST",
        params: { ledId },
    });
}

let ledCache = null

export const cleanLedCache = () =>{
    ledCache=null
}

export const getLeds = async () => {
    if (ledCache != null) {
        return ledCache;
    }

    const leds = JSON.parse(await getSetting('LED_CONFIG'));
    for (let key in leds) {
      if (leds.hasOwnProperty(key)) {
        leds[key] = parseInt(leds[key], 10);
      }
    }
  
    ledCache = leds;
    return leds;
  };
